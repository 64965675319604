// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#component_navbar {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
}

#navbar_contents {
    display: flex;
    justify-content:left;
    align-items: center;

    width: 100%;
    height: 100%;
}

.navbar_button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 10%;
    height: 90%;

    background-color: white;
    border-radius: 10px;
    border-style: solid;

    margin-left: 5px;
    margin-right: 5px;

    color: black;
    font-weight: bold;

    text-decoration: none;
    text-align: center;
}

.navbar_button:hover {
    cursor: pointer;
}

.navbar_button:active {
    box-shadow: 3px 3px 3px black inset;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;;IAEnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,UAAU;IACV,WAAW;;IAEX,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;;IAEnB,gBAAgB;IAChB,iBAAiB;;IAEjB,YAAY;IACZ,iBAAiB;;IAEjB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,YAAY;AAChB","sourcesContent":["#component_navbar {\n    height: calc(100% - 10px);\n    width: calc(100% - 10px);\n}\n\n#navbar_contents {\n    display: flex;\n    justify-content:left;\n    align-items: center;\n\n    width: 100%;\n    height: 100%;\n}\n\n.navbar_button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    width: 10%;\n    height: 90%;\n\n    background-color: white;\n    border-radius: 10px;\n    border-style: solid;\n\n    margin-left: 5px;\n    margin-right: 5px;\n\n    color: black;\n    font-weight: bold;\n\n    text-decoration: none;\n    text-align: center;\n}\n\n.navbar_button:hover {\n    cursor: pointer;\n}\n\n.navbar_button:active {\n    box-shadow: 3px 3px 3px black inset;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
