// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#page_home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 10px;
}

#home_contents {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: 98vh;
    width: 99vw;

    background-color: black;
}

#home_margin {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: calc(100% - 10px);
    width: calc(100% - 10px);
}

.home_element {
    background-color: white;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

#home_header {
    height: 10%;
    margin-bottom: 5px;
}

#home_main {
    height: 80%;
}

#home_footer {
    height: 10%;
    margin-top: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/assets/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,WAAW;;IAEX,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB,uBAAuB;IACvB,mBAAmB;;IAEnB,yBAAyB;IACzB,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;IACvB,WAAW;;IAEX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":["#page_home {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    margin: 10px;\n}\n\n#home_contents {\n    display: flex;\n    flex-direction: column;\n\n    justify-content: center;\n    align-items: center;\n\n    height: 98vh;\n    width: 99vw;\n\n    background-color: black;\n}\n\n#home_margin {\n    display: flex;\n    flex-direction: column;\n\n    justify-content: center;\n    align-items: center;\n\n    height: calc(100% - 10px);\n    width: calc(100% - 10px);\n}\n\n.home_element {\n    background-color: white;\n    width: 100%;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n#home_header {\n    height: 10%;\n    margin-bottom: 5px;\n}\n\n#home_main {\n    height: 80%;\n}\n\n#home_footer {\n    height: 10%;\n    margin-top: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
