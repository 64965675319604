// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/FadeIn/index.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,UAAU;EACV,sCAAsC;AACxC","sourcesContent":[".fade-appear,\n.fade-enter {\n  opacity: 0;\n}\n\n.fade-appear-active,\n.fade-enter-active {\n  opacity: 1;\n  transition: opacity 1000ms ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
