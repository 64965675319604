// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#page_martialarts {
    height: calc(100% - 10px);
    width: calc(100% - 10px);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#martialarts_contents {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#martialarts_image, #martialarts_text {
    width: 48%;
    height: 90%;
}

#martialarts_text {
    text-align: justify;

    border-style: solid;
    border-radius: 10px;
    background-color: white;

    padding: 10px;

    overflow: auto;
}

#martialarts_image {
    border-style: solid;
    border-radius: 10px;
    background-color: white;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

#martialarts_image img {
    margin: 10px;
}

#martialarts_logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1380px) {
    #martialarts_logos {
        height: 75%;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/martialarts.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wBAAwB;;IAExB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,mBAAmB;;IAEnB,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;;IAEvB,aAAa;;IAEb,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["#page_martialarts {\n    height: calc(100% - 10px);\n    width: calc(100% - 10px);\n\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n}\n\n#martialarts_contents {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n}\n\n#martialarts_image, #martialarts_text {\n    width: 48%;\n    height: 90%;\n}\n\n#martialarts_text {\n    text-align: justify;\n\n    border-style: solid;\n    border-radius: 10px;\n    background-color: white;\n\n    padding: 10px;\n\n    overflow: auto;\n}\n\n#martialarts_image {\n    border-style: solid;\n    border-radius: 10px;\n    background-color: white;\n    padding: 10px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n#martialarts_image img {\n    margin: 10px;\n}\n\n#martialarts_logos {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n@media (max-width: 1380px) {\n    #martialarts_logos {\n        height: 75%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
