import logo from './logo.svg';
import './App.css';

// importing pages
import Home from "./pages/Home.js"

function App() {
  return (
    <Home />
  );
}

export default App;
