// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#component_footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

#footer_contents {
    width: 75%;
}

#contact_elements {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#contact_elements p {
    font-size: 24px;
    font-weight: bold;

    border-style: solid;
    border-radius: 10px;
    padding: 5px;

    transition: box-shadow 0.3s ease-in, transform 0.3s ease-in;

    color:black;
    background-color: white;
}

#contact_elements a {
    text-decoration: none;
    color: inherit;

    transition: transform 0.3s ease-in;
}

#contact_elements p:hover {
    box-shadow: 5px 5px 5px;
    transform: translateY(-5px);
}

#contact_elements a:hover {
    text-decoration: none;
    color: inherit;
}

#contact_elements p:active {
    box-shadow: 5px 5px 5px black;
    background-color: black;
    color: white;
    border-color: black;
}

`, "",{"version":3,"sources":["webpack://./src/components/ContactBar/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;;IAEjB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;;IAEZ,2DAA2D;;IAE3D,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,cAAc;;IAEd,kCAAkC;AACtC;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["#component_footer {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    width: 100%;\n}\n\n#footer_contents {\n    width: 75%;\n}\n\n#contact_elements {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n}\n\n#contact_elements p {\n    font-size: 24px;\n    font-weight: bold;\n\n    border-style: solid;\n    border-radius: 10px;\n    padding: 5px;\n\n    transition: box-shadow 0.3s ease-in, transform 0.3s ease-in;\n\n    color:black;\n    background-color: white;\n}\n\n#contact_elements a {\n    text-decoration: none;\n    color: inherit;\n\n    transition: transform 0.3s ease-in;\n}\n\n#contact_elements p:hover {\n    box-shadow: 5px 5px 5px;\n    transform: translateY(-5px);\n}\n\n#contact_elements a:hover {\n    text-decoration: none;\n    color: inherit;\n}\n\n#contact_elements p:active {\n    box-shadow: 5px 5px 5px black;\n    background-color: black;\n    color: white;\n    border-color: black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
